import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
    const { t } = useTranslation(["home", "common"]);

    return (
        <footer className="footer" id="site-footer">
            <div className="header-lines-decoration">
                <span className="bg-secondary-color" />
                <span className="bg-blue" />
                <span className="bg-blue-light" />
                <span className="bg-orange-light" />
                <span className="bg-red" />
                <span className="bg-green" />
                <span className="bg-secondary-color" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                        <div className="widget w-info">
                            <div className="site-logo">
                                <Link to="/" className="full-block" />
                                <img src="/assets/img/egp_logo_footer.png" width={220} alt="EGP Vietnam" />
                            </div>
                            <p>{t("footer.description")}</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-lg-offset-1 col-md-3 col-sm-12 col-sm-offset-0 col-xs-12">
                        <div className="widget w-list">
                            <h5 className="widget-title" style={{ color: "#0d6096" }}>
                                {t("footer.navigation")}
                            </h5>
                            <ul className="list list--primary">
                                <li>
                                    <Link to="/">{t("nav.home")}</Link>
                                    <svg className="utouch-icon utouch-icon-arrow-right">
                                        <use xlinkHref="#utouch-icon-arrow-right" />
                                    </svg>
                                </li>
                                <li>
                                    <Link to="/about">{t("nav.about")}</Link>
                                    <svg className="utouch-icon utouch-icon-arrow-right">
                                        <use xlinkHref="#utouch-icon-arrow-right" />
                                    </svg>
                                </li>
                                <li>
                                    <Link to="/services">{t("nav.service")}</Link>
                                    <svg className="utouch-icon utouch-icon-arrow-right">
                                        <use xlinkHref="#utouch-icon-arrow-right" />
                                    </svg>
                                </li>
                                <li>
                                    <Link to="/contact">{t("nav.contact")}</Link>
                                    <svg className="utouch-icon utouch-icon-arrow-right">
                                        <use xlinkHref="#utouch-icon-arrow-right" />
                                    </svg>
                                </li>
                                <li>
                                    <Link to="/terms">{t("nav.tnc")}</Link>
                                    <svg className="utouch-icon utouch-icon-arrow-right">
                                        <use xlinkHref="#utouch-icon-arrow-right" />
                                    </svg>
                                </li>
                                <li>
                                    <Link to="/privacy">{t("nav.policy")}</Link>
                                    <svg className="utouch-icon utouch-icon-arrow-right">
                                        <use xlinkHref="#utouch-icon-arrow-right" />
                                    </svg>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-lg-offset-1 col-md-4 col-sm-12 col-sm-offset-0 col-xs-12">
                        <div className="widget w-contacts">
                            <h5 className="widget-title" style={{ color: "#0d6096" }}>
                                {t("footer.contact")}
                            </h5>
                            <div className="contact-item display-flex" style={{ width: 332 }}>
                                <svg className="utouch-icon utouch-icon-placeholder-3">
                                    <use xlinkHref="#utouch-icon-placeholder-3" />
                                </svg>
                                <Link
                                    className="info"
                                    style={{ cursor: "pointer", width: "100%" }}
                                    to="https://www.google.com/maps/place/Handico+Tower,+Ph%E1%BA%A1m+H%C3%B9ng,+M%E1%BB%85+Tr%C3%AC,+T%E1%BB%AB+Li%C3%AAm,+H%C3%A0+N%E1%BB%99i/data=!4m2!3m1!1s0x313454aad14949b5:0x50fde6fabfba0a79?sa=X&ved=2ahUKEwiZ0cu-l9GAAxV8qlYBHQIxC8IQ8gF6BAgQEAA&ved=2ahUKEwiZ0cu-l9GAAxV8qlYBHQIxC8IQ8gF6BAgSEAI"
                                    target="_blank"
                                >
                                    {t("contact.address", { ns: "common" })}
                                </Link>
                            </div>
                            <div className="contact-item display-flex" style={{ width: 332 }}>
                                <svg className="utouch-icon utouch-icon-placeholder-3">
                                    <use xlinkHref="#utouch-icon-placeholder-3" />
                                </svg>
                                <Link
                                    className="info"
                                    style={{ cursor: "pointer", width: "100%" }}
                                    to="https://www.google.com/maps/place/C%C3%B4ng+Ty+TNHH+1+Th%C3%A0nh+Vi%C3%AAn+ITAXA/@10.7778093,106.6928448,20.75z/data=!4m10!1m2!2m1!1zVOG6p25nIDIgJiAzLCBUb8OgIG5ow6AgSXRheGEgUC4gVsO1IFRo4buLIFPDoXUsIFEuIDMsIFRQSENN!3m6!1s0x31752f3b6b0bad9b:0x3e51f2c7ac1ec7b6!8m2!3d10.7779151!4d106.6929853!15sCjxU4bqnbmcgMiAmIDMsIFRvw6AgbmjDoCBJdGF4YSBQLiBWw7UgVGjhu4sgU8OhdSwgUS4gMywgVFBIQ01aOSI3dOG6p25nIDIgJiAzIHRvw6AgbmjDoCBpdGF4YSBwIHbDtSB0aOG7iyBzw6F1IHEgMyB0cGhjbZIBEGNvcnBvcmF0ZV9vZmZpY2WaASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVUmxkazR5WDFwM0VBReABAA!16s%2Fg%2F1w4533b6?entry=ttu"
                                    target="_blank"
                                >
                                    {t("contact.address2", { ns: "common" })}
                                </Link>
                            </div>
                            <div className="contact-item display-flex">
                                <svg className="utouch-icon utouch-icon-message">
                                    <use xlinkHref="#utouch-icon-message" />
                                </svg>
                                <Link to="mailto:info@egp.vn" className="info" style={{ cursor: "pointer" }}>
                                    info@egp.vn
                                </Link>
                            </div>
                        </div>
                        <div class="widget w-follow">
                            <ul>
                                <li>{t("contact.follow", { ns: "common" })}:</li>
                                <li>
                                    <Link to="https://www.facebook.com/egpvietnam/" target="_blank">
                                        <svg
                                            class="utouch-icon"
                                            viewBox="0 0 16 16"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            stroke-linejoin="round"
                                            stroke-miterlimit="1.414"
                                        >
                                            <path
                                                d="M15.117 0H.883C.395 0 0 .395 0 .883v14.234c0 .488.395.883.883.883h7.663V9.804H6.46V7.39h2.086V5.607c0-2.066 1.262-3.19 3.106-3.19.883 0 1.642.064 1.863.094v2.16h-1.28c-1 0-1.195.48-1.195 1.18v1.54h2.39l-.31 2.42h-2.08V16h4.077c.488 0 .883-.395.883-.883V.883C16 .395 15.605 0 15.117 0"
                                                fill-rule="nonzero"
                                            ></path>
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.linkedin.com/company/egpvietnam/" target="_blank">
                                        <svg
                                            class="utouch-icon"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            stroke-linejoin="round"
                                            stroke-miterlimit="1.414"
                                        >
                                            <path d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z" />{" "}
                                        </svg>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sub-footer">
                <Link className="back-to-top" to="#">
                    <svg className="utouch-icon utouch-icon-arrow-top">
                        <use xlinkHref="#utouch-icon-arrow-top" />
                    </svg>
                </Link>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span>
                                {t("copyright")} © &nbsp;
                                <Link to="/" className="sub-footer__link">
                                    EGP Vietnam
                                </Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
