import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import HOME_VI from "../locales/vi/home.json";
import HOME_EN from "../locales/en/home.json";
import COMMON_VI from "../locales/vi/common.json";
import COMMON_EN from "../locales/en/common.json";
import ABOUT_VI from "../locales/vi/about.json";
import ABOUT_EN from "../locales/en/about.json";
import SERVICES_VI from "../locales/vi/services.json";
import SERVICES_EN from "../locales/en/services.json";

// the translations
const resources = {
    en: {
        home: HOME_EN,
        common: COMMON_EN,
        about: ABOUT_EN,
        services: SERVICES_EN
    },
    vi: {
        home: HOME_VI,
        common: COMMON_VI,
        about: ABOUT_VI,
        services: SERVICES_VI
    }
};

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        resources,
        ns: ["common", "home", "about", "services"],
        fallbackLng: localStorage.getItem("lang") || "en",
        defaultNS: "home",
        debug: true,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
