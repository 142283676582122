import React from "react";
import "./style.css";
import { useTranslation } from "react-i18next";

const langs = [
    {
        value: "vi",
        name: "Tiếng Việt",
        icon: ""
    },
    {
        value: "en",
        name: "English",
        icon: ""
    }
];

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem("lang", lang);
    };

    return (
        <div className="lang-container">
            {/* <svg className="utouch-icon world utouch-icon-world-map global">
                <use xlinkHref="#utouch-icon-world-map" />
            </svg> */}
            <img className="global" src="/assets/img/global.svg" width={24} height={24} alt="" />

            <div className="lang-content">
                {langs.map((item, index) => (
                    <div key={index} className="lang-item" onClick={() => handleLanguageChange(item.value)}>
                        {item.name}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LanguageSwitcher;
