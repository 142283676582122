import React from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import LanguageSwitcher from "../../../components/LanguageSwitcher/index.js";
import { menuData } from "../../../configs/menu.js";

const Header = () => {
    const { t } = useTranslation(["home", "common"]);
    return (
        <header className="header header--menu-rounded header--blue-lighteen animated headroom--not-bottom slideDown headroom--top" id="site-header">
            <div className="header-lines-decoration">
                <span className="bg-secondary-color" />
                <span className="bg-blue" />
                <span className="bg-blue-light" />
                <span className="bg-orange-light" />
                <span className="bg-red" />
                <span className="bg-green" />
                <span className="bg-secondary-color" />
            </div>
            <div className="container">
                <a href="/#" id="top-bar-js" className="top-bar-link">
                    <svg className="utouch-icon utouch-icon-arrow-top">
                        <use xlinkHref="#utouch-icon-arrow-top" />
                    </svg>
                </a>
                <div className="header-content-wrapper">
                    <div className="site-logo">
                        <Link to="/" className="full-block" />
                        <img src="/assets/img/egp_logo_small.png" width={220} alt="EGP Vietnam" />
                    </div>
                    <nav id="primary-menu" className="primary-menu primary-menu-responsive">
                        {/* menu-icon-wrapper */}
                        <Link to="#" id="menu-icon-trigger" className="menu-icon-trigger showhide">
                            {/* <span className="mob-menu--title">Menu</span> */}
                            <span id="menu-icon-wrapper" className="menu-icon-wrapper" style={{ visibility: "visible" }}>
                                <svg width="1000px" height="1000px">
                                    <path
                                        id="pathD"
                                        d="M 300 400 L 700 400 C 900 400 900 750 600 850 A 400 400 0 0 1 200 200 L 800 800"
                                        style={{
                                            strokeDashoffset: "5803.15",
                                            strokeDasharray: "2901.57, 2981.57, 240"
                                        }}
                                    />
                                    <path
                                        id="pathE"
                                        d="M 300 500 L 700 500"
                                        style={{
                                            strokeDashoffset: 800,
                                            strokeDasharray: "400, 480, 240"
                                        }}
                                    />
                                    <path
                                        id="pathF"
                                        d="M 700 600 L 300 600 C 100 600 100 200 400 150 A 400 380 0 1 1 200 800 L 800 200"
                                        style={{
                                            strokeDashoffset: "6993.11",
                                            strokeDasharray: "3496.56, 3576.56, 240"
                                        }}
                                    />
                                </svg>
                            </span>
                        </Link>
                        <ul className="primary-menu-menu primary-menu-indented scrollable" style={{ maxHeight: 460 }}>
                            {menuData.map((menu, index) => (
                                <li key={index} className="menu-item-has-children">
                                    <NavLink to={menu.to}>{t(menu.translationKey)}</NavLink>
                                </li>
                            ))}
                            <li className="scrollable-fix" />
                        </ul>
                        <ul className="nav-add">
                            <li className="search search_main" style={{ display: "initial" }}>
                                <LanguageSwitcher />
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default Header;
