import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NotFound = () => {
    const { t } = useTranslation("common");
    return (
        <div>
            <Helmet>
                <title>Không tìm thấy trang</title>
                <script src="/assets/js/main.js"></script>
            </Helmet>
            <section className="page404">
                <div className="col-8 bg-secondary-color" />
                <div className="col-8 bg-primary-color" />
                <div className="col-8 bg-blue-light">
                    <h2 className="error">Error</h2>
                </div>
                <div className="col-8 bg-orange-light">
                    <h2 className="number">4</h2>
                </div>
                <div className="col-8 bg-red">
                    <h2 className="number">0</h2>
                </div>
                <div className="col-8 bg-green">
                    <h2 className="number">4</h2>
                </div>
                <div className="col-8 bg-secondary-color" />
                <div className="col-8 bg-primary-color" />
                <div className="page404-content">
                    <h2 className="title">{t("404.title")}</h2>
                    <h5 className="sub-title">
                        {t("404.sub-title")} <Link to="/">{t("404.homepage")}</Link>.
                    </h5>
                </div>
            </section>
        </div>
    );
};

export default NotFound;
