import React from "react";

const Loader = () => {
    return (
        <div
            id="hellopreloader"
            style={{
                position: "fixed",
                zIndex: 99999,
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                minWidth: "100%",
                background: 'url("/assets/svg/preload.svg") center center / 41px no-repeat rgb(102, 181, 255)',
                opacity: 1
            }}
        ></div>
    );
};

export default Loader;
